@tailwind base;
@tailwind components;
@tailwind utilities;

.logo{
  display: flex;
  justify-content: center;
}
body{background: #fff;font-family: arial,sans-serif!important;}
#root{height: 100vh;}
.login-card {
  background-color: #f4fdfc;
  width: 28rem;
  font-size: 1em;
  line-height: 1.5em;
  margin: 1.5rem auto 0;
  border: 1px solid #c1bed1;
  padding: 3rem;
}
.text-color{
  color: #352868;
}
.system-button.mainBtn{
  background: #352868;
}
.system-button.mainBtn:hover{
  background: #40307c;
}
.system-button.secondBtn{
  background: #6c757d;
}
.system-button.secondBtn:hover{
  background: #5c6369;
}

.link-color{
  color: #5b5b5b;
}
.login-border{
  border: 1px solid #bfc2d2;
}
.login-error{
  width: 100%;
}
.backWrapper{
  width: 25rem;
}
.main{
  width: calc(100% - 160px);
  padding: 2rem 3rem;
}
.sidebar{
  width: 160px;
  min-height: 100vh;
  background: #efefef;
}
.sidebar-icons{
  width: 50%;
  display: block;
  border: 2px solid #fff;
  background-color: #fff;
  border-radius: 1em;
  margin:  auto;
  color: #352868;
  text-decoration: none;
  cursor: pointer;
}
.sidebar-icons.selected{
  color: #352868;
  border-color: #352868;
}
.sidebar-icons:hover{
  color: #fff;
  background-color: #352868;
}
.page-title{
  color: #352868;
  font-size: 2rem;
  margin-bottom: 1rem;
}
.search-bar{
 
  width: 40rem;
  display: flex;
  align-items: center;
  height: 3rem;
  margin: 0 auto 2rem;
  padding: 0 0.5rem;
}
.box-design{
  border: 1px solid #999999;
  border-radius: 0.5rem;
  overflow: hidden;
}
.search-bar input{
  width:100%;
  outline: none;
  height: 100%;
  margin-left: 0.5rem;
}

.device-table{

  height: 80%;
  padding-right:0.25rem;
  padding-left: 1rem;
}
.device-table.history-table{
  height: 89%;
}
.scroller{
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: calc(100% - 4rem);
}
.table-header{
  padding-right: 1.25rem;
  border-radius: 0.25rem;
  margin: 1rem 1rem 1rem 0;
  padding-left: 1rem;
  background: #edebeb;
  align-items: center;
  min-height: 2rem;
}
.device-row{
  padding-right: 1.25rem;
  margin-bottom: 1rem;
  text-decoration: none;
  padding-left: 1rem;
}
.device-cell{
  width: 16%;
  color: #212529;
  white-space: normal;
  word-break: break-all;
  margin-right: 0.5%;
}
.spinner-border{
  width: 5rem!important;
  height: 5rem!important;
}
.spinner-container{
  text-align: center;
  margin-top: 10rem;
}

.project-text-color{
  color: #352868;
}
.indicator{
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}
.indicator.open{background-color: #00ff00;}
.indicator.locked{background-color: #ff0000;}

.tabs-table, .activity-table{min-width:1170px; width:80%; }
.tabs-table {
  max-height: calc(100% - 100px);
  overflow-y: auto;
}
.tabs-header, .activity-header{font-weight: bold};
.tabs-buttons{background: #f0efef; width: 200px;}
.info-left{
  background-color: #eee;
}
.single-tab{
  background: #d9d9d9; 
  border: 1px solid #a2a2a2; 
  cursor: pointer;
  min-width: 156px;
  border-radius: 1rem;
  height: 75px;}
.single-tab.selected{font-weight: bold;}
.single-tab.selected .selected-icon{display: block;}
.single-tab .selected-icon{display: none;}
.info-cell{
  background: #eee;
   width: 45%;
   min-width:200px;
   min-height: 50px;
   padding: 0.1rem 1rem;
   margin-left: 1rem;
   word-break: break-word;
   display: flex;
   align-items: center;

  }
.info-cell:first-child{
  margin-left: 0;
  font-weight: bold;
}
.info-row{margin-bottom: 1rem;}
.info-row:last-child{margin-bottom: 0;}

.activity-cell{
  width: 15%;
  color: #212529;
  white-space: normal;
    word-break: break-all;
    margin-right: 0.5%;
}
.activity-cell:first-child, .activity-cell:nth-child(2){
  width: 25%;
}
.table-header.activity-header{
  padding-right: 0;
}
.translate-center{
  transform: translateX(-50%);
}
.place-footer{
  top: calc(80% + 7.8rem);
  right: 3rem;
}
.place-footer-devices{
  top: calc(80% + 7.8rem);

}
.place-footer-history{
  top: calc(89% + 2.4rem);

}